<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label" style="width: 150px;">
                {{ $t("questionnaire.quesName") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="50"
                  v-model="searchParam.title"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  clearable
                  @change="search"
                />
              </div>
            </div>

            <!-- <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">{{$t('questionnaire.creater')}}</div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="50"
                  v-model="searchParam.createByName"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  clearable
                />
              </div>
            </div> -->

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("questionnaire.publishStatus") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="searchParam.status"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    v-for="item in publicStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>

            <!-- <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.tableTite6") }} 
              </div>

              <div class="edp-backend-conditionSearch-content">
                 <el-date-picker
                  v-model="times"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="-"
                  :start-placeholder="$t('edpBackendCommon.startDate')"
                  :end-placeholder="$t('edpBackendCommon.endDate')"
                >
                </el-date-picker> 
              </div>
            </div> -->
            <div class="edp-backend-conditionSearch-square conditionSearch-square-last">
              <el-button size="small" type="transparent" @click="clearSearch">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="search">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="openTemplateDialog"
                  >{{ $t("questionnaire.addQues") }}</el-button
                >
              </div>
            </div>
            <el-table :data="questionData" stripe style="width: 100%">
              <el-table-column
                prop="code"
                :label="$t('questionnaire.quesBatch')"
                width="175"
                fixed
              />
              <el-table-column
                prop="title"
                :label="$t('questionnaire.quesName')"
                min-width="520"
              />
              <!-- <el-table-column prop="todayNum" :label="$t('questionnaire.todayNew')" width="90"/> -->
              <el-table-column
                prop="totalNum"
                :label="$t('questionnaire.totalComplete')"
                width="174"
              />
              <el-table-column
                prop="createByName"
                :label="$t('questionnaire.creater')"
                width="150"
              />
              <!-- <el-table-column prop="avgTakeTime" :label="$t('questionnaire.averageTime')" width="188"/> -->
              <el-table-column
                prop="createTime"
                :label="$t('questionnaire.creationTime')"
                width="215"
              />
              <el-table-column
                prop="status"
                :label="$t('questionnaire.publishStatus')"
                width="174"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.status == "1"
                      ? $t("questionnaire.Published")
                      : $t("questionnaire.Unpublished")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="isDisable"
                :label="$t('questionnaire.visibility')"
                width="174"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.isDisable == "0"
                      ? $t("questionnaire.visible")
                      : $t("questionnaire.disabled")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('questionnaire.Actions')"
                width="272"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click.native="
                      $router.push({
                        path: 'backend-questionnaire-publish',
                        query: { id: scope.row.id, code: scope.row.code },
                      })
                    "
                    v-if="scope.row.status == '2'"
                    style="width: 60px"
                  >
                    {{ scope.row.status == "1" ? $t("questionnaire.Unpublish") : $t("questionnaire.Publish") }}
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="scope.row.status == '2'"
                  ></el-divider>
                  <el-button
                    type="text"
                    @click="publishQuestion(scope.row)"
                    v-if="scope.row.status == '1'"
                  >
                    {{ scope.row.status == "1" ? $t("questionnaire.Unpublish") : $t("questionnaire.Publish") }}
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="scope.row.status == '1'"
                  ></el-divider>
                  <el-button
                    type="text"
                    @click.native="questionView(2, scope.row)"
                  >
                    {{ $t("edpBackendCommon.edit") }}
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click.native="shareConfirm(scope.row)"
                  >
                    {{ $t("questionnaire.share") }}
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      {{ $t("edpBackendCommon.more") }}
                      <i class="el-icon-caret-bottom el-icon--right" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item
                        @click.native="questionView(1, scope.row)"
                        >{{ $t("edpBackendCommon.view") }}</el-dropdown-item
                      > -->
                      <el-dropdown-item
                        @click.native="
                          $router.push({
                            path: 'backend-questionnaire-dashboard',
                            query: { id: scope.row.id, code: scope.row.code },
                          })
                        "
                        >{{$t("questionnaire.viewResults")}}</el-dropdown-item
                      >
                      <el-dropdown-item
                        @click.native="copySurveyToTemplate(scope.row)"
                        >{{$t("questionnaire.saveAsTemplate")}}</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="copySurvey(scope.row)">{{
                        $t("edpBackendCommon.copy")
                      }}</el-dropdown-item>
                      <el-dropdown-item
                        @click.native="openQuestion(scope.row)"
                        >{{
                          scope.row.isDisable == "0" ? $t("questionnaire.disable") : $t("questionnaire.visible")
                        }}</el-dropdown-item
                      >
                      <el-dropdown-item
                        @click.native="deleteQuestion(scope.row)"
                        >{{ $t("edpBackendCommon.delete") }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-footer fjsb">
              <span class="table-pagination-text"
                >{{ total }} {{$t('edpBackendCommon.totalRecords')}}, {{ totalPage }}  {{$t('edpBackendCommon.pages')}}</span
              >
              <el-pagination
                :total="total"
                :current-page="pageNo"
                background
                hide-on-single-page
                layout="prev, pager, next"
                @current-change="currentChange"
              />
            </div>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <!-- 分享弹窗 -->
    <el-dialog
      :visible.sync="showShare"
      modal-append-to-body
      append-to-body
      width="26%"
      top="25%"
      class="share-wrap"
    >
      <p slot="title" class="titles">
        <a :href="dateils.qrcode" target="_blank" download="qrcode">
          <i class="el-icon-download" />下载
        </a>
      </p>
      <h4>分享二维码</h4>
      <p class="desc">请扫下方二维码分享该问卷</p>
      <img :src="dateils.qrcode" alt class="qrcode" />
      <p class="links">
        问卷链接
        <span>{{ dateils.generateUrl }}</span>
      </p>
      <el-button class="copy-btn" @click="onCopy(dateils.generateUrl)"
        >复制链接</el-button
      >
    </el-dialog>
    <!-- 选择文件模版 -->
    <el-dialog
      :title="$t('questionnaire.createNewEvaluation')"
      width="900px"
      :visible.sync="templateDialog.visible"
      custom-class="template-dialog"
    >
      <section>
        <div>
          <el-button type="primary" @click.native="addQuestion"
            >{{$t('questionnaire.createNewWithoutTemplate')}}</el-button
          >
        </div>
        <div class="text">
          <h3>{{$t('questionnaire.selectTemplate')}}</h3>
        </div>
        <div class="fjsb search-box">
          <span>{{$t('questionnaireTemplate.templateName')}}</span>
          <el-input
            v-model="templateDialog.searchParam.title"
            :placeholder="`${$t('edpBackendCommon.placeholder')} ${$t('questionnaireTemplate.templateName')}`"
            @change="fetchTemplateList"
          ></el-input>
          <el-button type="primary" @click.native="fetchTemplateList"
            >{{$t('edpBackendCommon.search')}}</el-button
          >
        </div>
        <section class="edp-backend-common-table" style="margin: 0">
          <el-table
            :data="templateDialog.templateData"
            style="width: 100%"
            highlight-current-row
            @current-change="handleTemplateCurrentChange"
          >
            <el-table-column label="" width="35">
              <template slot-scope="scope">
                <el-radio v-model="templateDialog.currentRow.id" :label="scope.row.id" name="currentRow">
                  <i />
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column
              prop="title"
              :label="$t('questionnaireTemplate.templateName')"
              width="520"
            >
              <template slot-scope="scope">
                <a
                  :href="`#/backend-template-detail?id=${scope.row.id}`"
                  target="_blank"
                  style="color: #3e886d; text-decoration: underline"
                  >{{ scope.row.title }}</a
                >
              </template>
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('questionnaire.creationTime')">
              <template slot-scope="scope">
                {{ utils.dateFormatFn(scope.row.createTime, "yyyy.MM.dd") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createBy"
              :label="$t('questionnaireTemplate.creater')"
            />
          </el-table>
          <div class="table-footer fjsb">
            <span class="table-pagination-text"
              >{{ templateDialog.total }} {{$t('edpBackendCommon.totalRecords')}},
              {{ templateDialog.totalPage }}  {{$t('edpBackendCommon.pages')}}</span
            >
            <el-pagination
              :total="templateDialog.total"
              :current-page="templateDialog.pageNo"
              :page-size="templateDialog.pageSize"
              background
              hide-on-single-page
              layout="prev, pager, next"
              @current-change="templateCurrentChange"
            />
          </div>
        </section>
      </section>

      <div slot="footer" class="dialog-footer">
        <section>
          <el-button @click="templateDialog.visible = false">{{
            $t("edpBackendCommon.return")
          }}</el-button>
          <el-button type="primary" @click.native="saveTemplateToSurvey"
            >{{$t("edpBackendCommon.create")}}</el-button
          >
        </section>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "questionnaires",
  data() {
    return {
      times: ["", ""],
      showShare: false,
      searchParam: {
        title: "",
        createByName: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      pageSize: 10,
      total: 0,
      pageNo: 1,
      totalPage: 0,
      publicStatusList: [
        {
          label: this.$t("questionnaire.Published"),
          value: "1",
        },
        {
          label: this.$t("questionnaire.Unpublished"),
          value: "2",
        },
      ],
      questionData: [],
      dateils: {
        pageAddress: "string",
        publishSystems: "string",
        qrUrl: "string",
      },
      templateDialog: {
        visible: false,
        pageSize: 5,
        total: 0,
        pageNo: 1,
        totalPage: 0,
        templateData: [],
        searchParam: {
          title: undefined,
        },
        currentRow: {},
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(o, n) {
        this.intiData();
      },
    },
    times(val) {
      console.log(val);
      if (val) {
        this.searchParam.startTime = val[0];
        this.searchParam.endTime = val[1];
      }
    },
  },
  mounted() {
    // this.intiData();
  },
  created() {},
  methods: {
    ...mapActions({
      surveyList: "survey/surveyList",
      surveyView: "survey/surveyView",
      surveyDelete: "survey/surveyDelete",
      offlineQuestion: "survey/offlineQuestion",
      onlineQuestion: "survey/onlineQuestion",
      turnoffQuestion: "survey/turnoffQuestion",
      turnonQuestion: "survey/turnonQuestion",
      surveyCopy: "survey/copySurvey",
      surveyCopyToTemplate: "survey/copySurveyToTemplate",
      surveyQrcode: "survey/surveyQrcode",
      templateList: "questionnaireTemplate/templateList",
      templateCreateSurvey: "questionnaireTemplate/templateCreateSurvey",
    }),
    async shareConfirm(record) {
      if (record.status === 1) {
        this.$router.push({
          path: "backend-questionnaire-share",
          query: { id: record.id, code: record.code },
        });
      } else {
        this.$confirm(
          this.$t('questionnaire.unpublishShareTips'),
          this.$t('edpBackendCommon.noticeTitle'),
          {
            confirmButtonText: this.$t('questionnaire.GotoPublish'),
            cancelButtonText: this.$t('edpBackendCommon.return'),
            center: false,
          }
        )
          .then(() => {
            this.$router.push({
              path: "backend-questionnaire-publish",
              query: { id: record.id, code: record.code },
            });
          })
          .catch(() => {});
      }
    },
    async saveTemplateToSurvey() {
      if (!this.templateDialog.currentRow.id) return;
      this.templateCreateSurvey({ id: this.templateDialog.currentRow.id }).then(
        (response) => {
          if (response.success) {
            this.$message({
              type: "success",
              message: this.$t('edpBackendCommon.actionSuccess'),
            });
            this.templateDialog.currentRow = {};
            this.templateDialog.visible = false;
            this.questionView(2, response.data);
          } else {
            this.$message({
              type: "warning",
              message: response.msg,
            });
          }
        }
      );
    },
    handleTemplateCurrentChange(val) {
      this.templateDialog.currentRow = val;
    },
    templateCurrentChange(num) {
      this.templateDialog.pageNo = num;
      this.fetchTemplateList();
    },
    fetchTemplateList() {
      const params = {
        current: this.templateDialog.pageNo,
        size: this.templateDialog.pageSize,
        title: this.templateDialog.searchParam.title
          ? this.templateDialog.searchParam.title
          : "",
        isDisable: 0,
      };
      this.templateList(params).then((res) => {
        if (res.success === true) {
          this.templateDialog.templateData = res.data;
          this.templateDialog.total = res.total;
          this.templateDialog.totalPage = res.totalPage;
        }
      });
    },
    clearSearch() {
      this.searchParam.title = "";
      this.searchParam.createByName = "";
      this.searchParam.status = "";
      this.searchParam.startTime = "";
      this.searchParam.endTime = "";
      this.pageNo = 1;
      this.times = ["", ""];
    },
    search() {
      this.pageNo = 1;
      this.intiData();
    },
    currentChange(num) {
      this.pageNo = num;
      this.intiData();
    },
    intiData() {
      const params = {
        current: this.pageNo,
        size: this.pageSize,
        createByName: this.searchParam.createByName,
        title: this.searchParam.title,
        status: this.searchParam.status,
        startTime: this.searchParam.startTime,
        endTime: this.searchParam.endTime,
      };
      this.surveyList(params).then((res) => {
        if (res.success === true) {
          this.questionData = res.data;
          this.total = res.total;
          this.totalPage = res.totalPage;
        }
      });
    },
    openTemplateDialog() {
      this.fetchTemplateList();
      this.templateDialog.currentRow = {};
      this.templateDialog.visible = true;
    },
    addQuestion() {
      this.$router.push({
        path: "/backend-addQuestionnaires",
        query: {
          type: 2,
        },
      });
    },
    questionView(type, row) {
      let types = type;
      if (row.status == 1 && types != 1) {
        types = 3;
      }
      if (row.isAnswer && row.status == 2 && types != 1) {
        types = 4;
      }
      let path = "/backend-addQuestionnaires";
      if (types === 1) {
        path = "/backend-checkQuestionnaires";
        window.sessionStorage.setItem("surveyAnswerInfo", JSON.stringify(row));
      }
      this.$router.push({
        path,
        query: {
          type: types,
          id: row.id,
        },
      });
    },
    deleteQuestion(row) {
      this.$confirm(`${this.$t('edpBackendCommon.delete')} ${this.$t('questionnaire.thisEvaluation')}`, this.$t('edpBackendCommon.noticeTitle'), {
        confirmButtonText: this.$t('edpBackendCommon.delete'),
        cancelButtonText: this.$t('edpBackendCommon.return'),
        center: false,
      })
        .then(() => {
          console.log(row);
          if (row.id) {
            this.surveyDelete(row.id).then((res) => {
              if (res.success === true) {
                this.pageNo = 1;
                this.$message({
                  type: "success",
                  message: this.$t("edpBackendCommon.actionSuccess"),
                });
                this.intiData();
              }
            });
          }
        })
        .catch(() => {});
    },
    publishQuestion(row) {
      let str = this.$t("questionnaire.Published");
      if (row.status == "1") {
        str = this.$t("questionnaire.Canclepublished");
      } else {
        str = this.$t("questionnaire.Published");
      }
      this.$confirm(`${this.$t('edpBackendCommon.confirm')} ${this.$t('questionnaire.Unpublish')} ${this.$t('questionnaire.thisEvaluation')}`, this.$t('edpBackendCommon.noticeTitle'), {
        confirmButtonText: str,
        cancelButtonText: this.$t('edpBackendCommon.return'),
        center: false,
      })
        .then(() => {
          if (row.id) {
            if (row.status == "1") {
              this.offlineQuestion({ id: row.id }).then((res) => {
                if (res.success === true) {
                  this.pageNo = 1;
                  this.$message({
                    type: "success",
                    message: this.$t("edpBackendCommon.actionSuccess"),
                  });
                  this.intiData();
                }
              });
            } else {
              this.onlineQuestion({ id: row.id }).then((res) => {
                if (res.success === true) {
                  this.pageNo = 1;
                  this.$message({
                    type: "success",
                    message: this.$t("edpBackendCommon.actionSuccess"),
                  });
                  this.intiData();
                }
              });
            }
          }
        })
        .catch(() => {});
    },
    openQuestion(row) {
      let str = this.$t("questionnaire.visible");
      if (row.isDisable == "0") {
        str = this.$t("questionnaire.disabled");
      } else {
        str = this.$t("questionnaire.visible");
      }
      this.$confirm(`${this.$t('edpBackendCommon.confirm')} ${str} ${this.$t('questionnaire.thisEvaluation')}`, this.$t('edpBackendCommon.noticeTitle'), {
        confirmButtonText: str,
        cancelButtonText: this.$t('edpBackendCommon.return'),
        center: false,
      })
        .then(() => {
          if (row.id) {
            if (row.isDisable == "0") {
              this.turnoffQuestion({ id: row.id }).then((res) => {
                if (res.success === true) {
                  this.pageNo = 1;
                  this.$message({
                    type: "success",
                    message: this.$t("edpBackendCommon.actionSuccess"),
                  });
                  this.intiData();
                }
              });
            } else {
              this.turnonQuestion({ id: row.id }).then((res) => {
                if (res.success === true) {
                  this.pageNo = 1;
                  this.$message({
                    type: "success",
                    message: this.$t("edpBackendCommon.actionSuccess"),
                  });
                  this.intiData();
                }
              });
            }
          }
        })
        .catch(() => {});
    },
    onShare(val) {
      this.surveyQrcode(val).then((res) => {
        if (res.success === true) {
          this.dateils = res.data;
          this.showShare = true;
        }
      });
    },
    onCopy(val) {
      var text = val;
      if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text);
      } else {
        var textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = text;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
      }
      this.$message({
        type: "success",
        message: "复制成功!",
      });
    },
    copySurvey(val) {
      if (val.id) {
        this.surveyCopy(val.id).then((res) => {
          if (res.success === true) {
            this.pageNo = 1;
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
            this.intiData();
          }
        });
      }
    },
    copySurveyToTemplate(val) {
      if (val.id) {
        this.surveyCopyToTemplate(val.id).then((res) => {
          if (res.success === true) {
            this.pageNo = 1;
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
            this.intiData();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.el-dialog.template-dialog {
  .text {
    padding: 20px 0;
    border-top: 1px dashed #555;
    margin-top: 20px;
  }
  .search-box {
    padding-bottom: 20px;
    .el-input {
      width: 70%;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
.searchTitle {
  text-align: left;
  margin-top: 16px;
  img {
    position: absolute;
    margin-top: 2px;
  }
  span {
    margin-left: 20px;
  }
}
.flex-buttons {
  top: 14px;
}
.searchBtn {
  text-align: right;
}
.mt20 {
  margin-top: 16px;
}
.row {
  margin-bottom: 18px;
}
.ftClas {
  color: #3e886d;
  cursor: pointer;
}
.share-wrap {
  border-radius: 10px;
  >>> .el-dialog__header {
    background: rgb(242, 242, 242);
    .titles {
      background: rgb(242, 242, 242);
      width: 90%;
      font-size: 14px;
      color: rgb(0, 9, 91);
      text-align: right;
      i {
        font-size: 14px;
      }
    }
    .el-dialog__close:before {
      font-size: 22px;
      color: #333;
    }
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 140px;
    height: 140px;
    margin: 10px auto;
    padding: 4px;
    border: 1px solid rgb(153, 153, 153);
  }
  .links {
    text-align: center;
    font-size: 14px;
    color: rgb(153, 153, 153);
    span {
      font-size: 12px;
      color: rgb(23, 0, 244);
      text-decoration: underline;
    }
  }
  .copy-btn {
    display: block;
    margin: 5px auto;
  }
}
</style>
<style lang="less">
.questionnaires {
  button {
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>
<style>
.el-dropdown {
  cursor: pointer;
  color: #4A4F54;
}
</style>
